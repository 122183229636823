<template>
  <div>
    <navbar />

    <bread-creamb :title="post.title" />
    <section class="blog-view">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-lg-8">
            <!-- blog content view start -->
            <div class="blog-content-view">

              <div class="blog-view-body">
                <h2 class="mt-3 mb-3 blog-view-content-heading">
                  {{ post.title }}
                </h2>
                <div class="d-flex mb-4">
                  <div class="date-item mr-2">
                    <feather-icon
                      class="icon-view"
                      icon="MessageSquareIcon"
                    /> <span>{{ date(post.created_at) }}</span>
                  </div>
                  <div class="comment-item">
                    <feather-icon
                      class="icon-view"
                      icon="MessageSquareIcon"
                    /> <span>{{ post.comments && post.comments.length }}</span>
                  </div>
                </div>
                <img
                  :src="getImage(post.image)"
                  alt="user"
                  class="blog-image mt- 3"
                >
                <div
                  class="mt-3 mb-4"
                  v-html="post.body"
                />
                <hr>
                <div class="author-view">
                  <b-avatar v-if="post.author && post.author.avatar === null" />
                  <b-avatar
                    v-else
                    :src="getAvatar(post.author.avatar)"
                  />
                  <span>{{post.author && post.author.user_info.full_name }}, Author</span>
                </div>
                <hr>

              </div>
            </div>
            <!-- blog content view end -->
          </div>
          <div class="col-md-4 col-lg-4">
            <blog-sidebar />
          </div>
        </div>
      </div>
    </section>

    <app-footer />
  </div>
</template>

<script>
import moment from 'moment'
import { BButton, BAvatar } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Navbar from './site-content/Navbar.vue'
import AppFooter from './site-content/AppFooter.vue'
import BreadCreamb from './site-pages/BreadCreamb.vue'
import BlogSidebar from './site-pages/BlogSidebar.vue'

export default {
  components: {
    Navbar,
    AppFooter,
    BreadCreamb,
    BlogSidebar,
    BButton,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      comment: '',
      reply_body: '',
      is_reply: null,
      comment_id: '',
    }
  },
  computed: {
    post() {
      return this.$store.state.site.post
    },
    comments() {
      return this.$store.state.blog.comments
    },
    user() {
      return this.$store.state.auth.auth.user
    },
  },
  mounted() {
    this.fetchSingleData()
  },
  methods: {
    getImage(image) {
      if (image === undefined) {
        return null
      }
      return `${process.env.VUE_APP_STORAGE_PATH}/${image}`
    },
    getAvatar(image) {
      if (image === undefined || image === null) return null
      return `${process.env.VUE_APP_STORAGE_PATH}/${image}`
    },
    showReply(id) {
      this.is_reply = id
      this.comment_id = id
    },
    hideReply() {
      this.is_reply = null
    },
    showContent(title) {
      this.$router.push(`/blog/${title}`).catch(() => { })
    },
    date(data) {
      return moment(data).format('ddd DD-MMM-YY')
    },
    fetchSingleData() {
      const { id } = this.$route.params
      this.$store.dispatch('site/showSinglePost', id)
    },
    GetBlogData() {
      const { id } = this.$route.params
      this.$store.dispatch('site/showSinglePost', id)
    },
    postComment() {
      const data = { post_id: this.post.id, body: this.comment, author_id: this.user.id }
      this.$store.dispatch('blog/postComments', data)
      this.comment = ''
      this.fetchSingleData()
    },
    replyComment() {
      const data = {
        post_id: this.post.id, body: this.reply_body, author_id: this.user.id, parent_id: this.comment_id,
      }
      this.$store.dispatch('blog/postComments', data)
      this.reply_body = ''
      this.is_reply = null
      this.fetchSingleData()
    },
  },
}
</script>

<style>

</style>
